.texts--3XPlm {
  color: hsla(0, 0%, 15%, 1); }
  .texts__label--22iou {
    padding: 0 5px;
    background: hsla(var(--color-secondary-h), var(--color-secondary-s), var(--color-secondary-l), var(--color-secondary-a)); }
  .texts__title-block--q9XxJ {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-word;
    overflow-wrap: break-word; }
    @media (max-width: 989px) {
      .texts__title-block--q9XxJ {
        padding-bottom: 20px; } }
  .texts__paragraph--2Z_fW {
    display: flex;
    flex-direction: column; }
  .texts__paragraph-title--SEqAf {
    color: hsla(0, 0%, 65%, 1); }
  .texts__button--for-not-mobile--1eGzA {
    margin-top: 40px; }
    @media (max-width: 989px) {
      .texts__button--for-not-mobile--1eGzA {
        display: none; } }
  .texts__button--for-mobile--2jwTF {
    display: flex;
    justify-content: center;
    margin-top: 30px; }
    @media (min-width: 990px) {
      .texts__button--for-mobile--2jwTF {
        display: none; } }
