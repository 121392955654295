.texts--Fr2JQ {
  --svg-primary-color: hsla(0, 0%, 15%, 1); }
  @media (min-width: 990px) {
    .texts__container--3JQLJ {
      padding: 30px 25px;
      border: 1px solid hsla(0, 0%, 93%, 1);
      border-radius: 6px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 10%); } }
  .texts__units-row--3b5II {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media (min-width: 990px) {
      .texts__units-row--3b5II {
        border-bottom: 1px solid hsla(0, 0%, 93%, 1); }
        .texts__units-row--3b5II:last-child {
          border-bottom: 0 none; } }
  .texts__unit--2PzPz {
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px; }
    @media (max-width: 989px) {
      .texts__unit--2PzPz {
        padding-bottom: 0;
        word-break: break-word;
        overflow-wrap: break-word; }
        .texts__unit--2PzPz:nth-child(2n) {
          padding-right: 0;
          padding-left: 7.5px; }
        .texts__unit--2PzPz:nth-child(2n + 1) {
          padding-right: 7.5px;
          padding-left: 0; } }
    @media (min-width: 990px) {
      .texts__unit--2PzPz {
        flex: 1 1;
        border-right: 1px solid hsla(0, 0%, 93%, 1); }
        .texts__unit--2PzPz:first-child {
          padding-left: 0; }
        .texts__unit--2PzPz:last-child {
          padding-right: 0;
          border-right: 0 none; } }
  @media (max-width: 989px) {
    .texts__units-row--3b5II:first-child .texts__unit--2PzPz:nth-child(1),
    .texts__units-row--3b5II:first-child .texts__unit--2PzPz:nth-child(2) {
      padding-top: 0; } }
  @media (min-width: 990px) {
    .texts__units-row--3b5II:first-child .texts__unit--2PzPz {
      padding-top: 0; }
    .texts__units-row--3b5II:last-child .texts__unit--2PzPz {
      padding-bottom: 0; } }
  .texts__unit-content--19Kz0 {
    color: hsla(0, 0%, 65%, 1); }
