@media (min-width: 990px) {
  .texts--LxGwP {
    align-items: stretch; } }

.texts__unit--34DSu {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid hsla(0, 0%, 93%, 1);
  border-radius: 6px; }
  @media (max-width: 989px) {
    .texts__unit--34DSu {
      margin-bottom: 20px; }
      .texts__unit--last--2I6ui {
        margin-bottom: 0; } }

.texts__unit-content--2ej3O {
  color: hsla(0, 0%, 65%, 1); }
