.texts--3ORxl {
  --svg-primary-color: var(--color-primary); }
  .texts__content--3Hzt1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid hsla(0, 0%, 93%, 1);
    border-radius: 6px; }
  .texts__description--3l8yL {
    padding-right: 15px;
    padding-left: 15px;
    color: hsla(0, 0%, 15%, 1); }
