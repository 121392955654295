@media (min-width: 990px) {
  .texts--1iT1i {
    align-items: stretch; } }

.texts__unit--9octa {
  margin-bottom: 15px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: hsla(0, 0%, 97%, 1);
  border-radius: 6px; }
  @media (max-width: 989px) {
    .texts__unit--last-in-row--1xMvU {
      margin-bottom: 0; } }
  @media (min-width: 990px) {
    .texts__unit--last-in-column--f3Spa {
      margin-bottom: 0; } }

.texts__content--3MS7T {
  display: flex; }

.texts__icon--2CVsl {
  --svg-primary-color: hsla(0, 0%, 15%, 1);
  flex-shrink: 0;
  margin-left: 5px; }

.texts__button-container--3_-pO {
  display: flex;
  flex-direction: column;
  align-items: center; }
