.texts--2THpo {
  color: hsla(0, 0%, 15%, 1); }
  @media (max-width: 989px) {
    .texts__unit--1Nnve {
      margin-top: 30px; }
      .texts__unit--1Nnve:first-child {
        margin-top: 0; } }
  .texts__unit-title--3xcCO {
    border-bottom: 1px solid hsla(0, 0%, 93%, 1);
    color: hsla(0, 0%, 65%, 1); }
  .texts__button-container--18NDt {
    display: flex;
    flex-direction: column;
    align-items: center; }
